export const API_ERROR_CODE_UNDEFINED = 'API_ERROR_CODE_UNDEFINED'
export const NETWORK_ERROR = 'NETWORK_ERROR'
export const NETWORK_OFFLINE = 'NETWORK_OFFLINE'
export const KIOSK_INSTALLATION_BRV_00001 = 'KIOSK_INSTALLATION_BRV_00001'
export const KIOSK_INSTALLATION_BRV_00002 = 'KIOSK_INSTALLATION_BRV_00002'
export const KIOSK_INSTALLATION_BRV_00003 = 'KIOSK_INSTALLATION_BRV_00003'
export const KIOSK_INSTALLATION_BRV_00004 = 'KIOSK_INSTALLATION_BRV_00004'
export const KIOSK_INSTALLATION_BRV_00005 = 'KIOSK_INSTALLATION_BRV_00005'
export const KIOSK_INSTALLATION_BRV_00006 = 'KIOSK_INSTALLATION_BRV_00006'
export const KIOSK_VERIFY_LICENSE_FROM_APP_TOKEN_BRV_000007 =
  'KIOSK_VERIFY_LICENSE_FROM_APP_TOKEN_BRV_000007'
export const KIOSK_INVALID_CUSTOM_ID_BRV_000009 =
  'KIOSK_INVALID_CUSTOM_ID_BRV_000009'
export const KIOSK_INVALID_EMPLOYEE_CREDENTIALS_BRV_000010 =
  'KIOSK_INVALID_EMPLOYEE_CREDENTIALS_BRV_000010'
export const KIOSK_VERIFY_EMPLOYEE_CUSTOM_ID_BRV_0000011 =
  'KIOSK_VERIFY_EMPLOYEE_CUSTOM_ID_BRV_0000011'
export const KIOSK_VERIFY_EMPLOYEE_PERMISSIONS_BRV_0000012 =
  'KIOSK_VERIFY_EMPLOYEE_PERMISSIONS_BRV_0000012'
