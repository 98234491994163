const errorCode = (error) => {
  const isString = typeof error === typeof String()
  return isString ? error : error.code
}

export default {
  name: 'KioskFormNotifications',
  functional: true,
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: 'VRow'
    },
    form: {
      type: Object,
      required: true
    },
    errorCode: {
      type: Function,
      default: errorCode
    }
  },
  render(h, context) {
    const { form } = context.props
    if (!form.state.error) {
      return null
    }

    const errorCode = context.props.errorCode(form.state.error)
    if (errorCode && context.scopedSlots[errorCode]) {
      return context.scopedSlots[errorCode]({ errorCode, form })
    }

    return context.scopedSlots.default({ errorCode, form })
  }
}
