<template>
  <VCard
    class="cardPrimary layout row wrap align-center justify-center"
    style="min-height: 90% !important;"
    tile
  >
    <KioskGuardUserInteractionRequired
      @user-inactif="$router.push(redirectOnClose)"
    />
    <VRow>
      <VCol>
        <KioskAdminCredentialsForm @submitted="onAdminLoginFormSubmitted" />
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
import KioskGuards from '@/guards'
import KioskAdminCredentialsForm from './components/KioskAdminCredentialsForm'

export default {
  name: 'KioskAdmin',
  components: {
    KioskAdminCredentialsForm,
    ...KioskGuards
  },
  props: {
    redirectOnClose: {
      type: Object,
      default() {
        return { name: 'dial.index' }
      }
    },
    redirectOnSubmit: {
      type: Object,
      default() {
        return { name: 'admin.settings' }
      }
    }
  },
  mounted() {
    this.$emit('change:toolbar-actions', {
      close: {
        icon: 'close',
        trigger: () => {
          this.$router.push(this.redirectOnClose)
        }
      }
    })
  },
  methods: {
    onAdminLoginFormSubmitted(submitOk) {
      if (submitOk) {
        this.$router.push(this.redirectOnSubmit)
      }
    }
  }
}
</script>
