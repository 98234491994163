<template>
  <VContainer class="fill-height">
    <VRow justify="center">
      <VCol cols="6">
        <KioskForm v-if="form" v-model="form" name="dialAdmin">
          <template #submitted="submittedProps">
            <slot name="submitted" v-bind="submittedProps" />
          </template>
          <template #default>
            <VRow>
              <VCol cols="12" :style="formErrorStyle" mb-4>
                <KioskDialFormNotifications :form="form" />
              </VCol>
              <VCol cols="12">
                <KioskFormField
                  v-model="form.fields.username"
                  solo
                  autocomplete="off"
                  autocorrect="off"
                  autofill="off"
                />
              </VCol>
              <VCol cols="12">
                <KioskFormField
                  v-model="form.fields.password"
                  type="password"
                  solo
                  autocomplete="off"
                  autocorrect="off"
                  autofill="off"
                />
              </VCol>
              <VCol cols="12" text-xs-center>
                <VBtn
                  large
                  block
                  type="submit"
                  color="primary"
                  :loading="form.state.loading"
                  :disabled="form.$v.$invalid"
                  data-heap-id="field_submit"
                >
                  <span v-text="form.$t('login.btn.text')" />
                </VBtn>
              </VCol>
            </VRow>
          </template>
        </KioskForm>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { required } from '@/validators'
import { FormBuilder } from '@/lib/kiosk'
import KioskForm from '@/components/KioskForm'
import KioskFormField from '@/components/KioskFormField'
import KioskDialFormNotifications from '@/views/dial/components/KioskDialFormNotifications'
import { KIOSK_VERIFY_EMPLOYEE_PERMISSIONS_BRV_0000012 } from '../errors'

export default {
  name: 'KioskAdminDialCredentialsForm',
  components: {
    KioskForm,
    KioskFormField,
    KioskDialFormNotifications
  },
  props: {
    formData: {
      type: Function,
      default() {
        return {
          username: null,
          password: null
        }
      }
    }
  },
  data() {
    return {
      form: null,
      fields: this.formData()
    }
  },
  validations() {
    return {
      fields: {
        username: {
          required
        },
        password: {
          required
        }
      }
    }
  },
  computed: {
    formErrorStyle() {
      const getHeightByErrorCode = (heightDefault) => {
        const { error } = this.form.state
        if (!error) {
          return heightDefault
        }

        return error.code === KIOSK_VERIFY_EMPLOYEE_PERMISSIONS_BRV_0000012
          ? 100
          : heightDefault
      }

      return {
        height: `${getHeightByErrorCode(65)}px`
      }
    }
  },
  mounted() {
    this.form = FormBuilder.fromComponent(this)
      .setFields((as) => ({
        username: as.component('VTextField'),
        password: as.component('VTextField')
      }))
      .setSubmitHandler((formState) => this.verify(formState))
      .build('dial')
  },
  methods: {
    async verify(payload) {
      await this.$store.dispatch('kiosk/verifyEmployeeCredentials', {
        perms: ['kiosk/app/settings'],
        ...payload
      })
    }
  }
}
</script>
