<template>
  <KioskFormNotifications :form="form">
    <template #[errorCodes.KIOSK_INSTALLATION_BRV_000006]="{errorCode}">
      <VDialog :value="true" color="error">
        <VCard tile>
          <VCardTitle>
            <span class="headline">
              <span v-text="form.$t(`errors.${errorCode}.dialog.title`)" />
            </span>
          </VCardTitle>
          <VCardText v-text="form.$t(`errors.${errorCode}.dialog.title`)" />
        </VCard>
      </VDialog>
    </template>
    <template #default="{ errorCode }">
      <VAlert :value="true" type="error" transition="scale-transition">
        <span v-text="form.$t(`errors.${errorCode}.alert.text`)" />
      </VAlert>
    </template>
  </KioskFormNotifications>
</template>

<script>
import KioskFormNotifications from '@/components/KioskFormNotifications'

export default {
  name: 'KioskDialFormNotifications',
  components: {
    KioskFormNotifications
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      errorCodes: {}
    }
  }
}
</script>
